import React from 'react';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import cert1 from '../../img/cert1.png';
import cert2 from '../../img/cert2.png';
import Layout from '../../components/Layout';
import left from '../../img/arrow-left-white.svg';
import right from '../../img/arrow-right-white.svg';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGallery: false,
      currentGalleryIndex: 0,
      isValidated: false,
    };

    this.certs = [cert2, cert1];
  }

  showGallery() {
    this.setState({
      showGallery: true,
    });
  }

  hideGallery() {
    this.setState({
      showGallery: false,
    });
  }

  rightButtonClicked() {
    const index =
      this.state.currentGalleryIndex >= this.certs.length - 1
        ? 0
        : this.state.currentGalleryIndex + 1;

    this.setState({
      currentGalleryIndex: index,
    });
  }

  leftButtonClicked() {
    const index =
      this.state.currentGalleryIndex <= 0
        ? this.certs.length - 1
        : this.state.currentGalleryIndex - 1;

    this.setState({
      currentGalleryIndex: index,
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    const label = e.target.nextSibling;
    if (e.target.value === '') {
      label.classList.remove('hidden');
    } else {
      label.classList.add('hidden');
    }
  };

  render() {
    return (
      <Layout>
        <section className="section" style={{ marginBottom: '100px' }}>
          <div className="container contact-page-container">
            <div className="columns">
              <div className="column is-1"></div>
              <div className="column is-10 column-big-responsive">
                <div
                  className="content has-text-centered"
                  style={{ marginTop: '4rem', marginBottom: '4rem' }}
                >
                  <h3
                    className="has-text-weight-semibold is-size-3 has-text-justified"
                  >
                    Cześć,
                  </h3>
                  <p className="has-text-justified">
                    Jestem{' '}
                    <span
                      className="text-pink"
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.showGallery()}
                    >
                      <b>certyfikowaną</b>
                    </span>{' '}
                    life coachką i wspieram kobiety, które chcą wprowadzić pozytywne zmiany w swoim życiu, odnaleźć siebie i tworzyć nowy rozdział na własnych zasadach.
                  </p>
                  <p className="has-text-justified">
                    <b>Jak wygląda wspólna praca?</b></p>
                    <p className="has-text-justified">Na początku przyjrzymy się Twojej sytuacji, określimy, co wymaga zmiany, i nazwijmy Twoje potrzeby. Rozpoznamy przeszkody oraz opracujemy sposoby ich pokonania, wykorzystując Twoje mocne strony. Następnie stworzymy plan działania, podzielony na małe kroki, które łatwiej wprowadzisz w życie.
                  </p>
                  <p className="has-text-justified"> <b>Bezpłatna konsultacja</b></p>
                  <p className="has-text-justified">
                  Pierwsze, niezobowiązujące spotkanie (30 minut) to okazja, by się poznać, omówić Twoje potrzeby i wyjaśnić, jak wygląda współpraca coachingowa.                  </p>
                  
                  <p className="has-text-justified">
                  <b>Proces coachingowy</b></p><p className="has-text-justified"> Proces zwykle obejmuje 5–10 sesji po godzinie, dostosowanych do Twoich potrzeb. Pracujemy online dla Twojej wygody, a dla zapracowanych oferuję coaching e-mailowy – idealny przy pracy w swoim tempie.                  </p>
                 
                  <p className="has-text-justified">
                  Zapraszam Cię na spotkanie i wspólne tworzenie życia, które pokochasz.
                  </p>
                  <Link className="btn" to="/kontakt" style={{ marginTop: '50px' }}>
                    Umów się na bezpłatną konsultację
                  </Link>
                </div>
              </div>
              <div className="column is-1"></div>
            </div>
          </div>
          {this.state.showGallery ? (
            <div className="cert-gallery">
              <div
                className="cert-gallery-background"
                onClick={() => this.hideGallery()}
              ></div>
              <img
                src={this.certs[this.state.currentGalleryIndex]}
                onClick={() => this.hideGallery()}
              ></img>
              <div
                className="gallery-arrow gallery-arrow-left"
                onClick={() => this.leftButtonClicked()}
              >
                <img src={left} />
              </div>
              <div
                className="gallery-arrow gallery-arrow-right"
                onClick={() => this.rightButtonClicked()}
              >
                <img src={right} />
              </div>
            </div>
          ) : null}
        </section>
      </Layout>
    );
  }
}
